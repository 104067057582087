import * as React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { featured } from "../products/featured"

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "100%",
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "5%",
    backgroundSize: "contain",
  },
  cardContent: {
    flexGrow: 1,
  },
  autoMargin: {
    margin: "auto",
  },
  price: {
    paddingTop: "5px",
  },
  h1: {
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  h2: {
    paddingTop: "30px",
  },
}))

const StorePage: React.FC = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Helmet>
        <title>Loja | Papelzinho ™</title>
      </Helmet>
      <div className="storeMargin">
        <h2 className={classes.h1}>
          <span className="font-weight-bolder">
            Bem-vindo a loja Papelzinho
          </span>
        </h2>
        <p className={classes.autoMargin}>
          Agora além de organizar o seu amigo secreto, o Papelzinho também
          indica os presentes mais legais para comprar pro seu amigo! Em
          parceria com a Amazon, nosso time faz uma curadoria especial e indica
          os melhores presentes de amigo secreto e, o melhor de tudo, você pode
          comprar com todo o conforto e segurança que a Amazon oferece!
          Aproveite!
        </p>
        <h4 className={classes.h2}>Produtos em destaque</h4>
        <Grid container spacing={2}>
          {featured.map((item, index) => (
            <Grid item key={index} xs={12} sm={4} md={3}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={item.image}
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography align="center" variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  {/* <Typography
                    align="center"
                    variant="body2"
                    className={classes.price}
                  >
                    {item.price}
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.autoMargin}
                    onClick={() => {
                      window.open(item.link)
                    }}
                  >
                    Comprar na amazon
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Layout>
  )
}

export default StorePage
