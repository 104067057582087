export const featured = [
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/71KsTiNXbdL._AC_SL1500_.jpg",
    title: "Mi Band 5 - Versão Global",
    price: "R$ 170,90",
    link: "https://amzn.to/36g7LKQ",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/51NjUyqABrL._SX446_BO1,204,203,200_.jpg",
    title: "Box Sherlock Holmes",
    price: "R$ 73,50",
    link: "https://amzn.to/3AypdIc",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61UIqGJL46L._AC_SL1500_.jpg",
    title: "Fones de Ouvido Xiaomi Redmi AirDots 2",
    price: "R$ 121,58",
    link: "https://amzn.to/3jM653O",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/51EnXKM%2BEcL._AC_SL1000_.jpg",
    title: "Relógio Inteligente Xiaomi Mi Band 3",
    price: "R$ 99,90",
    link: "https://amzn.to/3ysOLVE",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/81WUZkIhPNL._AC_SL1500_.jpg",
    title: "Jogo Imagem e Ação 2",
    price: "R$ 74,90",
    link: "https://amzn.to/3AyAvwi",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/51pY9X7O5sL._AC_.jpg",
    title: "Jogo Facas Inox 9PC",
    price: "R$ 55,90",
    link: "https://amzn.to/3xp2gFo",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/51utldCcoCL._AC_SL1100_.jpg",
    title: "Corda de Pular Fitness",
    price: "R$ 27,00",
    link: "https://amzn.to/3yrh1I0",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/41FLSLuoLgL._AC_.jpg",
    title: "Difusor de Óleo Essencial",
    price: "R$ 141,00",
    link: "https://amzn.to/3hKlsH6",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/815nAMwl39L._AC_SL1500_.jpg",
    title: "Jogo Uno",
    price: "R$ 14,99",
    link: "https://amzn.to/2V5Qt0u",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/71naGFZIOOL._AC_SL1500_.jpg",
    title: "Palavras Cruzadas",
    price: "R$ 39,90",
    link: "https://amzn.to/3xlEXwg",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/81uUvw0dW4L._AC_SL1500_.jpg",
    title: "Depilador - Aqua Deluxe",
    price: "R$ 159,90",
    link: "https://amzn.to/3AzHR2B",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/51or2JqGKYL._AC_SL1100_.jpg",
    title: "Fone de Ouvido - Bluetooth",
    price: "R$ 84,69",
    link: "https://amzn.to/3Azn52W",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/81vl65fe%2B7L._AC_SL1500_.jpg",
    title: "Pipoqueira Mondial",
    price: "R$ 129,90",
    link: "https://amzn.to/3jQj5Wa",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61VG1p6EpNL._AC_SL1000_.jpg",
    title: "Educativo Primeiras Palavras",
    price: "R$ 29,90",
    link: "https://amzn.to/3AA4VOI",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61%2Bc74LkIsL._AC_SL1500_.jpg",
    title: "Cafeteira Prensa Francesa",
    price: "R$ 39,99",
    link: "https://amzn.to/3dOvTZh",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/71evkELzSSS._AC_SX695_.jpg",
    title: "Alpargata Espadrille - Havaianas",
    price: "R$ 59,99",
    link: "https://amzn.to/3AviP4N",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61FtKidXFIL._AC_SL1168_.jpg",
    title: "Black Stories Cinema",
    price: "R$ 32,99",
    link: "https://amzn.to/2Vj71CB",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61T289wfwZL._AC_SY879_.jpg",
    title: "Relógio digital de matriz binária",
    price: "R$ 124,99",
    link: "https://amzn.to/2TAkHbS",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/81kx%2B9NwrNL._AC_SL1500_.jpg",
    title: "Aparador de Pelos Mondial",
    price: "R$ 119,89",
    link: "https://amzn.to/3qUnA3s",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61%2BT6Bc5WLL._AC_SL1000_.jpg",
    title: "Churrasqueira Califórnia",
    price: "R$ 155,90",
    link: "https://amzn.to/3wlVLSC",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/719xCNsVorS._AC_SL1500_.jpg",
    title: "Whisky Jack Daniel's",
    price: "R$ 144,44",
    link: "https://amzn.to/3yuCGz7",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/71bsb-XWmaS._AC_SL1000_.jpg",
    title: "JBL Go 2",
    price: "R$ 165,00",
    link: "https://amzn.to/2Va49rn",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/41LsCDRACkL._AC_.jpg",
    title: "Ring Light Portátil Iluminador Led",
    price: "R$ 77,50",
    link: "https://amzn.to/3jPQ5O0",
  },
  {
    image:
      "https://images-na.ssl-images-amazon.com/images/I/71po6cbSjDL._AC_SL1500_.jpg",
    title: "Amigos de Merda",
    price: "R$ 101,01",
    link: "https://amzn.to/3ADNnRY",
  },
]

export default {
  featured,
}
